<template>
    <div v-if="showBanner" class="mobile-download-banner">
        <div class="close-banner" @click="showBanner = false">
            x
        </div>
        <div class="mobile-download-container">
            <div class="row no-gutters align-items-center content">
                <div class="col-auto">
                    <div class="mobile-banner-logo">
                        <img src="@assets/img/icons/ic-testflight.svg" width="34px" alt="Memod">
                    </div>
                </div>
                <div class="col">
                    <p>App Update required!<br><span>Click to get the latest version</span></p>
                </div>
                <div class="col-auto">
                    <a href="https://memod.com/download-testflight" target="_blank" class="btn">
                        Download Now
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MobileDownloadBanner",
    data: () => ({ showBanner: true })
}
</script>

<style lang="scss" scoped>
.mobile-download-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-top: 60px;
    z-index: 600;
    color: white;
    text-decoration: none;

    .close-banner {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background: #02AAB0;
        position: absolute;
        font-size: 14px;
        padding: 5px;
        line-height: 0;
        width: 15px;
        height: 15px;
        right: 4px;
        bottom: 47px;
        cursor: pointer;
    }

    @media(max-width: 374px) {
        padding: 7px;
    }

    .mobile-download-container {
        border: 1px solid #38383A;
        background: linear-gradient(90deg, #02AAB0 0%, #00CDAC 100%);
        padding: 14px 12px;

        .content {
            margin: auto;
            max-width: 400px;
        }

        .mobile-banner-logo {
            margin-right: 10px;
            width: 36px;
            height: 36px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media(max-width: 400px) {
                margin-right: 5px;
            }
        }

        p {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;

            @media(max-width: 400px) {
                font-size: 13px;
            }
        }

        .btn {
            margin-right: 10px;
            color: #38383A;
            background-color: #fff;
            padding: 5px 12px;
            font-weight: 700;

            @media(max-width: 374px) {
                padding: 4px 7px;
                font-size: 13px;
            }

            &:not(:disabled):not(.disabled):active {
                color: white;
            }
        }
    }
}
</style>
